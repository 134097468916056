import { Spin, Alert } from 'antd';

const Loading = () => {
   
    return (
        <Spin tip="Buscando...">
            <Alert
            message="Buscando Datos"
            description="Cargando datos espere por favor..."
            type="info"
            />
        </Spin>
    )
}

export default Loading
