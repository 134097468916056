import React  from 'react'
import {Drawer, Avatar} from 'antd';
import '../../Assets/Css/page.css';





const ModalMenu = ({ OpenMenu, Loading, handleCancelMenu}) => {
  
    

    const onClose = () => {
       handleCancelMenu(false);
    };
    

   

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };
    
    return (
            <Drawer
                title="Menu"
                    width={window.innerWidth > 900 ? '25%' : '70%'}
                onClose={onClose}
                open={OpenMenu}
                key='left'
                placement={'left'}
                bodyStyle={{ paddingBottom: 80 }}
                
            >
       
           

                <Avatar
                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                    icon={<Icon type="UserOutlined" key="handlehead" />}
                />
           
           
                 
           
             
           
           
        </Drawer>
    )
}

export default ModalMenu
